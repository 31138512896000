<template>
  <q-list
    class="q-py-md q-px-sm full-width column gap-y-md sidebar-menu"
    v-if="inboxes"
  >
    <sidebar-menu-item
      :to="{ name: 'notifications' }"
      :label="$t('notificationsPage.label')"
      :unread-count="unreadCount"
    >
      <template #avatar>
        <q-icon name="sym_r_notifications" size="20px" />
      </template>
    </sidebar-menu-item>
    <sidebar-inbox-group
      v-for="inbox in inboxes"
      :key="inbox.id"
      :inbox="inbox"
    />
    <sidebar-menu-item :to="{ name: 'products' }" :label="$t('Products')">
      <template #avatar>
        <q-icon name="sym_r_category" size="20px" />
      </template>
    </sidebar-menu-item>
    <sidebar-menu-item
      v-if="organization?.useCustomerData"
      :to="{ name: 'customers' }"
      :label="$t('Customers')"
    >
      <template #avatar>
        <q-icon name="sym_r_groups" size="20px" />
      </template>
    </sidebar-menu-item>
  </q-list>
</template>

<script setup lang="ts">
import { useInboxesStore } from "@/stores/inboxes";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { useNotificationsStore } from "@/stores/notifications";
import { storeToRefs } from "pinia";
import SidebarInboxGroup from "./SidebarInboxGroup.vue";
import SidebarMenuItem from "./SidebarMenuItem.vue";

const { inboxes } = storeToRefs(useInboxesStore());
const { organization } = storeToRefs(useCurrentOrganizationStore());
const { unreadCount } = storeToRefs(useNotificationsStore());
</script>

<style lang="scss">
.sidebar-menu {
  .q-item {
    border-radius: $generic-border-radius;
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>
