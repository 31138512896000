<template>
  <q-layout view="lHh lpR fFf">
    <template v-if="is404">
      <q-page-container class="page-container">
        <div class="page-container-inner">
          <div
            class="page shadow-1 column items-center justify-center text-lg gap-md text-neutral-7"
          >
            <div>{{ $t("loggedInLayout.organizationNotFound") }}</div>
            <div>
              <q-btn to="/" class="no-decoration">
                {{ $t("loggedInLayout.backToRoot") }}
              </q-btn>
            </div>
          </div>
        </div>
      </q-page-container>
    </template>
    <template v-else>
      <sidebar-drawer />
      <q-page-container class="page-container">
        <div class="page-container-inner">
          <div class="page shadow-1">
            <router-view />
          </div>
        </div>
      </q-page-container>
    </template>
  </q-layout>
</template>

<script setup lang="ts">
import SidebarDrawer from "@/components/Sidebar/SidebarDrawer.vue";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { useCurrentUserStore } from "@/stores/currentUser";
import { storeToRefs } from "pinia";

async function hydrateUserStore() {
  const userStore = useCurrentUserStore();
  await userStore.loadUser();
}
hydrateUserStore();

const { is404 } = storeToRefs(useCurrentOrganizationStore());
</script>

<style lang="scss">
.page-container {
  background-color: $app-background;
  height: calc(100vh);

  .page-container-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;

    .page {
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: $page-background;
      border-radius: 4px;
    }
  }
}
</style>
