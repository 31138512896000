export default {
  newInboxDialog: {
    label: "Ordner erstellen",
    title: "Neuen Dokumenten-Ordner erstellen",
    documentUploadEnabled: "Dokumenten-Upload aktivieren",
    ok: "Erstellen",
  },
  organization: {
    general: {
      label: "Allgemein",
      title: "Organisation",
      description: "Organisations-Einstellungen bearbeiten",
    },
    members: {
      label: "Mitglieder",
      title: "Mitglieder verwalten",
      description: "Einstellen, wer Zugriff auf Ihre Organisation hat",
      editMemberDialog: {
        changePassword: "Passwort ändern",
        newPassword: "Neues Passwort",
      },
    },
    positionDetection: {
      label: "Positionserkennung",
      title: "Positionserkennung verwalten",
      description: "Schlüsselwörter für die Positionserkennung festlegen",
      positiveKeywords: "Positive Schlüsselwörter",
      negativeKeywords: "Negative Schlüsselwörter",
      save: "Schlüsselwörter speichern",
      notifySaved: "Schlüsselwörter gespeichert",
    },
    supplierTypeDetection: {
      label: "Fabrikatserkennung",
      title: "Fabrikatserkennung verwalten",
      description: "Schlüsselwörter für die Fabrikatserkennung festlegen",
      ownKeywords: "Eigenfabrikate Schlüsselwörter",
      externalKeywords: "Fremdfabrikate Schlüsselwörter",
      save: "Speichern",
      notifySaved: "Einstellungen gespeichert",
      showManufacturerTypes: "Fabrikate anzeigen",
    },
    exportConfiguration: {
      label: "ERP-Export Anfragen",
      save: "Speichern",
      notifySaved: "Konfiguration gespeichert",
      exportFunction: "Protokoll",
      renderFunction: "Format",
      title: "ERP-Export Anfragen",
      description: "ERP-Export von Anfragen konfigurieren",
      exportEnabled: "Export aktiviert",
    },
    productImportConfiguration: {
      label: "ERP-Import Produkte",
      save: "Speichern",
      notifySaved: "Konfiguration gespeichert",
      importFunction: "Methode",
      title: "ERP-Import Produkte",
      description: "ERP-Import von Produkten konfigurieren",
      isEnabled: "Import aktiviert",
    },
    customerImportConfiguration: {
      label: "ERP-Import Kunden",
      save: "Speichern",
      notifySaved: "Konfiguration gespeichert",
      importFunction: "Methode",
      title: "ERP-Import Kunden",
      description: "ERP-Import von Kunden konfigurieren",
      isEnabled: "Import aktiviert",
    },
    usageDashboard: {
      label: "Nutzung",
      title: "Nutzungs-Dashboard",
      description: "Nutzungsstatistiken für Ihre Organisation",
      month: "Monat",
      amountOfInquiries: "Anzahl Anfragen",
    },
  },
  inbox: {
    general: {
      label: "Allgemein",
      title: "Dokumenten-Ordner",
      description: "Allgemeine Dokumenten-Ordner-Einstellungen bearbeiten",
      deleteInbox: "Dokumenten-Ordner löschen",
      confirmDelete: {
        title: "Dokumenten-Ordner löschen",
        message:
          "Möchten Sie diesen Dokumenten-Ordner wirklich löschen? Alle Dokumente und Einstellungen gehen unwiderbringlich verloren.",
        ok: "Löschen",
      },
      notifyDeleted: "Dokumenten-Ordner gelöscht",
      saveInbox: "Speichern",
      notifySaved: "Einstellungen gespeichert",
      displayDates: {
        label: "Daten in Übersicht",
        SUBMISSION_CREATED: "Abgabe und Eingang",
        CUSTOMER_SUBMISSION_SUBMISSION: "Submission und Abgabe",
        CUSTOMER_SUBMISSION_CREATED: "Submission und Eingang",
      },
    },
    email: {
      label: "E-Mail-Eingang",
      title: "E-Mail-Eingang",
      description: "E-Mail-Adresse für den Dokumenteneingang verwalten",
      intakeAddressHelpText:
        "E-Mails, die an diese Adresse gesendet werden, werden in den Dokumenten-Ordner importiert.",
      copyToClipboard: "In Zwischenablage kopieren",
      copiedToClipboard: "In Zwischenablage kopiert",
    },
    members: {
      label: "Mitglieder",
      title: "Mitglieder verwalten",
      description: "Einstellen, wer Zugriff auf den Dokumenten-Ordner hat",
    },
    tags: {
      label: "Tags",
      title: "Tags verwalten",
      description: "Tags für den Dokumenten-Ordner verwalten",
      confirmDelete: {
        title: "'{label}' löschen",
        message:
          "Möchten Sie den Tag '{label}' wirklich löschen? Der Tag wird von allen Anfragen entfernt.",
      },
      createTag: "Neuen Tag erstellen",
      labelRequired: "Name ist erforderlich",
    },
    customStatuses: {
      label: "Status",
      title: "Statuswerte konfigurieren",
      description: "Statuswerte für den Dokumenten-Ordner definieren",
      createCustomStatus: "Neuen Status erstellen",
      confirmDelete: {
        title: "'{name}' löschen",
        message:
          "Möchten Sie den Status '{name}' wirklich löschen? Der Status wird von allen Anfragen entfernt.",
      },
      originalStatusLabels: {
        REVIEWING: "Zu prüfen",
        ARCHIVED: "Archiviert",
      },
      nameRequired: "Name ist erforderlich",
    },
  },
  account: {
    profile: {
      label: "Profil",
      title: "Profil bearbeiten",
      description: "Ihr kinisto Profil bearbeiten",
      deleteAccount: "Account löschen",
      deleteAccountDescription:
        "Ihr Account wird unwiderruflich gelöscht. Alle Daten und Einstellungen gehen verloren.",
      confirmDelete: {
        title: "Account löschen",
        message: "Möchten Sie Ihren Account wirklich löschen?",
      },
      accountSettingsUpdated: "Profil aktualisiert",
      accountDeleted: "Account gelöscht",
    },
    access: {
      label: "Zugriff",
      title: "Zugriff verwalten",
      description: "Zugriff auf Ihr kinisto Konto verwalten",
      newPassword: "Neues Passwort",
      confirmPassword: "Passwort bestätigen",
      changePassword: "Passwort ändern",
      passwordsDoNotMatch: "Passwörter stimmen nicht überein",
      passwordUpdated: "Passwort aktualisiert",
    },
    notifications: {
      label: "Benachrichtigungen",
      title: "Benachrichtigungen verwalten",
      description: "Benachrichtigungen für Ihr kinisto Konto verwalten",
      email: "E-Mail-Benachrichtigungen erhalten",
      notificationsUpdated: "Benachrichtigungs-Einstellungen aktualisiert",
    },
  },
};
