import * as api from "@/api/inquiry";
import type { Inbox } from "@/types/inbox";
import {
  CHANGEABLE_BASE_INQUIRY_STATUSES,
  type InquiryStatus,
  type ShortInquiry,
} from "@/types/inquiry";
import type { User } from "@/types/user";
import type { Ref } from "vue";

export function useMultiInquiryActions(
  inbox: Ref<Inbox | null>,
  inquiries: Ref<ShortInquiry[] | undefined>,
  selectedInquiries: Ref<ShortInquiry[]>,
  loadData: () => Promise<void>
) {
  async function assignInquiry(inquiryId: number, user: User | null) {
    if (!inquiries.value) return;
    const inquiry = inquiries.value.find((inq) => inq.id === inquiryId);
    if (!inquiry) return;

    const previouslyAssignedUser = inquiry.assignedUser;

    inquiry.assignedUser = user;

    const userId = user ? user.id : null;

    try {
      await api.updateInquiry(inbox.value!.id, inquiryId, {
        assignedUserId: userId,
      });
    } catch (e) {
      inquiry.assignedUser = previouslyAssignedUser;
    }
  }

  async function downloadSelectedInquiriesFiles() {
    const selectedIds = selectedInquiries.value.map((inquiry) => inquiry.id);
    await api.downloadMultipleInquiriesFiles(inbox.value!.id, selectedIds);
  }

  async function downloadSelectedInquiriesValues() {
    const selectedIds = selectedInquiries.value.map((inquiry) => inquiry.id);
    await api.downloadMultipleInquiriesValues(inbox.value!.id, selectedIds);
  }

  async function moveSelectedInquiries(targetInboxId: number) {
    const selectedIds = selectedInquiries.value.map((inquiry) => inquiry.id);

    await api.moveMultipleInquiriesToAnotherInbox(
      inbox.value!.id,
      targetInboxId,
      selectedIds
    );
    await loadData();
  }

  async function deleteSelectedInquiries() {
    const selectedIds = selectedInquiries.value.map((inquiry) => inquiry.id);

    inquiries.value = inquiries.value?.filter(
      (inquiry) => !selectedIds.includes(inquiry.id)
    );

    selectedInquiries.value = [];

    await api.deleteMultipleInquiries(inbox.value!.id, selectedIds);
    await loadData();
  }

  async function assignSelectedInquiries(user: User | null) {
    const selectedIds = selectedInquiries.value.map((doc) => doc.id);

    inquiries.value
      ?.filter((doc) => selectedIds.includes(doc.id))
      .forEach((doc) => {
        doc.assignedUser = user;
      });

    await api.updateMultipleInquiries(inbox.value!.id, selectedIds, {
      assignedUserId: user?.id ?? null,
    });
    await loadData();
  }

  async function updateInquiryStatus(
    inquiryId: number,
    newStatus: InquiryStatus
  ) {
    if (!inquiries.value) return;
    const inquiry = inquiries.value.find((inq) => inq.id === inquiryId);
    if (!inquiry) return;

    inquiry.status = newStatus;
    await api.updateInquiry(inbox.value!.id, inquiryId, {
      status: newStatus.name,
    });
  }

  async function updateSelectedInquiriesStatus(newStatus: InquiryStatus) {
    const selectedIds = selectedInquiries.value
      // only select IDs of inquiries whose status can be changed
      .filter((inquiry) =>
        CHANGEABLE_BASE_INQUIRY_STATUSES.includes(inquiry.status.originalStatus)
      )
      .map((inquiry) => inquiry.id);

    inquiries.value
      ?.filter((inquiry) => selectedIds.includes(inquiry.id))
      .forEach((inquiry) => {
        inquiry.status = newStatus;
      });

    await api.updateMultipleInquiries(inbox.value!.id, selectedIds, {
      status: newStatus.name,
    });
    await loadData();
  }

  return {
    assignInquiry,
    assignSelectedInquiries,
    downloadSelectedInquiriesFiles,
    downloadSelectedInquiriesValues,
    moveSelectedInquiries,
    deleteSelectedInquiries,
    updateInquiryStatus,
    updateSelectedInquiriesStatus,
  };
}
