<template>
  <q-drawer
    v-model="isOpen"
    show-if-above
    side="left"
    behavior="desktop"
    class="sidebar flex column no-wrap"
    :width="220"
  >
    <q-item class="row items-center q-pt-md">
      <q-item-section>
        <q-btn
          v-if="organization"
          class="q-mini-drawer-hide sidebar-organization-button"
          flat
          padding="xs none"
          align="left"
        >
          <div class="ellipsis">
            <user-avatar :user="user" class="q-mr-sm" />
            {{ organization.name }}
          </div>
          <q-menu anchor="bottom right" self="top right" :offset="[0, 4]">
            <account-menu />
          </q-menu>
        </q-btn>
      </q-item-section>
      <q-item-section side>
        <div class="row">
          <sidebar-toggle-button />
        </div>
      </q-item-section>
    </q-item>
    <div class="sidebar-menu-container">
      <sidebar-menu class="full-width" />
    </div>
  </q-drawer>
</template>

<script setup lang="ts">
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { useCurrentUserStore } from "@/stores/currentUser";
import { useSidebarStateStore } from "@/stores/sidebarState";
import { storeToRefs } from "pinia";
import AccountMenu from "../AccountMenu.vue";
import UserAvatar from "../User/UserAvatar.vue";
import SidebarMenu from "./SidebarMenu.vue";
import SidebarToggleButton from "./SidebarToggleButton.vue";

const { organization } = storeToRefs(useCurrentOrganizationStore());

const { user } = storeToRefs(useCurrentUserStore());

const { isOpen } = storeToRefs(useSidebarStateStore());
</script>

<style lang="scss">
.sidebar {
  background-color: $app-background;
  position: sticky;
  top: 0;
  height: 100vh !important;
  overflow-y: auto;

  .sidebar-organization-button {
    max-width: 100%;
  }

  .sidebar-menu-container {
    flex-grow: 0;
    flex-shrink: 1;
    height: 100%;
    overflow: auto;
  }
}
</style>
