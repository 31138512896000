<template>
  <div class="full-width">
    <q-select
      use-input
      hide-selected
      fill-input
      clearable
      outlined
      dense
      v-model="selectedCustomer"
      :label="$t('Customer')"
      :options="foundCustomers"
      :option-label="getCustomerRepresentation"
      @filter="searchCustomers"
      :loading="isLoading"
      @clear="emit('update:customer', null)"
      @update:model-value="$emit('update:customer', $event)"
      map-options
      hide-dropdown-icon
    >
      <template #no-option>
        <q-item>
          <q-item-section class="text-grey">
            {{ $t("No results found") }}
          </q-item-section>
        </q-item>
      </template>
      <template #option="{ opt, selected, focused, itemProps }">
        <q-item
          v-bind="itemProps"
          :class="{
            'bg-primary-1': selected || focused,
            'text-primary-9': selected || focused,
          }"
        >
          <q-item-section>
            <q-item-label>
              <highlight-text
                :text="getCustomerIdAndNameRepresentation(opt)"
                :search-text="currentSearchText"
              />
            </q-item-label>
            <q-item-label caption>
              <highlight-text
                :text="getAddressRepresentation(opt)"
                :search-text="currentSearchText"
              />
            </q-item-label>
            <q-item-label caption v-if="opt.salesOffice" class="text-italic">
              {{ getCustomerSalesOfficeRepresentation(opt) }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<script setup lang="ts">
import { findCustomers } from "@/api/customer";
import HighlightText from "@/components/HighlightText.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { NUM_CUSTOMERS_TO_RETRIEVE_IN_ADVANCE } from "@/config/constants";
import type { Customer } from "@/types/customer";
import type { Inquiry } from "@/types/inquiry";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  inquiry: Inquiry;
  customer: Customer | null;
  disabled?: boolean;
}>();

const selectedCustomer = ref<Customer | null>(props.customer);
const foundCustomers = ref<Customer[]>([]);
const currentSearchText = ref("");

const emit = defineEmits<{
  "update:customer": [customer: Customer | null];
}>();

const isLoading = ref(false);
const { organizationId } = useRouteParams();

async function searchCustomers(
  searchText: string,
  doneFn: (callbackFn: () => void) => void
) {
  currentSearchText.value = searchText;
  if (!searchText.length) {
    foundCustomers.value = [];
    isLoading.value = false;
    return;
  }

  isLoading.value = true;
  try {
    foundCustomers.value = await findCustomers(
      organizationId.value,
      searchText,
      NUM_CUSTOMERS_TO_RETRIEVE_IN_ADVANCE
    );
    doneFn(() => foundCustomers.value);
  } finally {
    isLoading.value = false;
  }
}

function getCustomerRepresentation(customer: Customer | null) {
  if (!customer) return "";
  let result = getCustomerIdAndNameRepresentation(customer);
  if (customer.salesOffice) {
    result += ` (${getCustomerSalesOfficeRepresentation(customer)})`;
  }
  return result;
}

function getCustomerSalesOfficeRepresentation(customer: Customer) {
  if (!customer.salesOffice) return null;
  return t("Sales office") + ": " + customer.salesOffice;
}

function getCustomerIdAndNameRepresentation(customer: Customer | null) {
  if (!customer) return "";
  return [customer.externalId, customer.name].filter(Boolean).join(" - ");
}

function getAddressRepresentation(customer: Customer) {
  const townRepresentation = [customer.postCode, customer.town]
    .filter(Boolean)
    .join(" ");
  return [customer.street, townRepresentation, customer.countryCode]
    .filter(Boolean)
    .join(", ");
}
</script>

<style scoped lang="scss"></style>
