<template>
  <q-field :model-value="true" clearable @clear="clear">
    <input
      ref="inputEl"
      type="date"
      class="date-input-el"
      :value="internalValue"
      @blur="handleBlur"
      :disabled="props.disable"
    />
  </q-field>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

const props = defineProps<{
  modelValue: Date | null | undefined;
  disable?: boolean;
}>();
const emit = defineEmits<{
  "update:model-value": [date: Date | null | undefined];
  save: [];
}>();

const inputEl = ref<HTMLInputElement | null>(null);

const internalValue = computed(() => {
  if (!props.modelValue) return null;
  return (
    props.modelValue.getFullYear() +
    "-" +
    (props.modelValue.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    props.modelValue.getDate().toString().padStart(2, "0")
  );
});

function clear() {
  if (!inputEl.value) return;

  inputEl.value.value = "";
  emit("update:model-value", null);
  emit("save");
}

function handleBlur() {
  if (!inputEl.value) return;

  const date = inputEl.value.valueAsDate;
  if (!date) {
    inputEl.value.value = internalValue.value || "";
    return;
  }
  emit("update:model-value", date);
  emit("save");
}
</script>

<style scoped lang="scss">
.date-input-el {
  width: 100%;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}
</style>
