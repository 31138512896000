import * as api from "@/api/inbox";
import { useRouteParams } from "@/composables/useRouteParams";
import type { NewInbox, ShortInbox } from "@/types/inbox";
import { defineStore } from "pinia";
import { computed, ref, watch, type ComputedRef } from "vue";

export const useInboxesStore = defineStore("inboxes", () => {
  const inboxes = ref<ShortInbox[] | null>(null);

  const { organizationId } = useRouteParams();

  async function loadInboxes() {
    if (isNaN(organizationId.value)) return;

    try {
      inboxes.value = await api.listInboxes(organizationId.value);
    } catch (error: any) {
      if (error.response?.status === 404) {
        // 404 errors are handled by currentOrganization store
        inboxes.value = null;
        return;
      } else {
        throw error;
      }
    }
  }

  watch(organizationId, loadInboxes, { immediate: true });

  const currentInbox = computed(() => {
    const { inboxId } = useRouteParams();
    return getById(inboxId.value).value;
  });

  async function getInbox(inboxId: number) {
    return await api.getInbox(organizationId.value, inboxId);
  }

  function getById(inboxId: number) {
    return computed(
      () => inboxes.value?.find((inbox) => inbox.id === inboxId) || null
    ) as ComputedRef<ShortInbox | null>;
  }

  async function addInbox(inbox: NewInbox) {
    if (!inboxes.value) return;
    const newInbox = await api.createInbox(organizationId.value, inbox);
    inboxes.value.push(newInbox);
  }

  async function deleteInbox(inboxId: number) {
    if (!inboxes.value) return;
    await api.deleteInbox(organizationId.value, inboxId);
    inboxes.value = inboxes.value.filter((inbox) => inbox.id !== inboxId);
  }

  async function updateInbox(inboxId: number, data: any) {
    if (!inboxes.value) return;
    const updatedInbox = await api.updateInbox(
      organizationId.value,
      inboxId,
      data
    );
    inboxes.value = inboxes.value.map((i) =>
      i.id === updatedInbox.id ? updatedInbox : i
    );
  }

  return {
    inboxes,
    currentInbox,
    getById,
    getInbox,
    addInbox,
    deleteInbox,
    updateInbox,
  };
});
