<template>
  <div>
    <input
      ref="inputEl"
      :model-value="modelValue"
      :placeholder="$t('Search')"
      :class="{
        'product-search-bar': true,
        'product-search-bar-active': modelValue && modelValue.length,
        'standalone-input': true,
      }"
      @input="handleInput"
    />
    <q-btn
      flat
      dense
      color="neutral-5"
      icon="sym_r_close"
      v-if="modelValue"
      @click="clear"
    />
  </div>
</template>

<script setup lang="ts">
import { debounce } from "quasar";
import { ref } from "vue";

const props = withDefaults(
  defineProps<{ modelValue: string | null; debounceMs?: number }>(),
  {
    debounceMs: 300,
  }
);

const emit = defineEmits<{
  "update:modelValue": [value: string | null];
}>();

const inputEl = ref<HTMLInputElement | null>(null);

function handleInput(event: Event) {
  const value = (event.target as HTMLInputElement).value;
  emitUpdate(value);
}

function clear() {
  emitUpdate("");
  inputEl.value!.value = "";
}

const emitUpdate = debounce((value: string) => {
  emit("update:modelValue", value);
}, props.debounceMs);
</script>

<style lang="scss">
.product-search-bar {
  width: 200px;
  transition: width 0.3s;

  &:focus-visible,
  &-active {
    width: 600px;
  }
}
</style>
