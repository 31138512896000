<template>
  <div class="full-width row items-start no-wrap q-px-md q-py-sm">
    <div class="full-width col">
      <div class="row items-center no-wrap first-row full-width">
        <single-product-input
          ref="productInputEl"
          :group="group"
          :position="position"
          v-model:product="product"
          :disabled="disabled"
          :class="[
            'full-width',
            product?.hasVariant ? 'product-input-with-variant' : 'col',
          ]"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          @keydown.stop.left
          @keydown.stop.right
          @keydown.stop.escape="productInputEl?.blur()"
        />
        <input
          ref="variantInputEl"
          v-if="product && product.hasVariant"
          name="variant"
          class="standalone-input full-width col"
          v-model="variant"
          :disabled="disabled"
          :placeholder="$t('Product Variant')"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          @keydown.stop.left
          @keydown.stop.right
          @keydown.stop.escape="variantInputEl?.blur()"
        />
        <q-btn
          v-if="position && product && product.isVariantConfigurable"
          flat
          dense
          size="sm"
          color="neutral-7"
          icon="sym_r_tune"
          @click="
            showProductVariantConfigurator(group, position, variant || '')
          "
        >
          <q-tooltip>{{
            $t("inquiryPositionsPage.offerPositionGroup.configureVariant")
          }}</q-tooltip>
        </q-btn>
        <number-input
          ref="amountInputEl"
          v-model:value="amount"
          nullable
          :precision="3"
          :disabled="disabled"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          :highlighted="amount === null"
          @keydown.stop.left
          @keydown.stop.right
          @keydown.stop.escape="amountInputEl?.blur()"
        />
        <unit-select v-model:selected="unit" :choices="unitChoices" />
      </div>
      <div class="row items-center no-wrap space-between full-width q-mt-xs">
        <q-checkbox
          class="checkbox-margin-right"
          dense
          size="sm"
          v-model="isAlternative"
          :disable="disabled"
          :label="$t('Alternative position')"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        />
        <q-space />
        <q-btn
          dense
          flat
          size="sm"
          color="neutral-8"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
        >
          <q-badge
            v-if="props.position?.notes?.length"
            color="primary"
            transparent
            class="q-mr-xs notes-badge"
          >
            1
          </q-badge>
          {{ $t("Notes") }}
          <notes-menu v-model:notes="notes" :disabled="disabled" />
        </q-btn>
      </div>
    </div>
    <div class="col-auto q-ml-sm">
      <q-btn
        :style="{ visibility: props.position ? 'visible' : 'hidden' }"
        dense
        flat
        size="sm"
        icon="sym_r_delete"
        color="neutral-6"
        :disabled="disabled"
        @click="deleteOfferPosition(props.group.id, props.position!.id)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import NumberInput from "@/components/NumberInput.vue";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import type { OfferPosition } from "@/types/offerPosition";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import { computed, nextTick, ref, watch } from "vue";
import NotesMenu from "./NotesMenu.vue";
import SingleProductInput from "./SingleProductInput.vue";
import UnitSelect from "./UnitSelect.vue";
import type { Product, Unit } from "@/types/product";
import {
  convertAmount,
  getAmountAndUnitForNewOfferPosition,
} from "@/utils/productUnitAmount";
import { useProductVariantConfigurator } from "@/composables/useProductVariantConfigurator";

const { deleteOfferPosition, updateOfferPosition, addOfferPosition } =
  useCurrentOfferPositionGroupsStore();

const { showProductVariantConfigurator } = useProductVariantConfigurator();

const props = defineProps<{
  group: OfferPositionGroup;
  position?: OfferPosition;
  disabled: boolean;
}>();

const emit = defineEmits(["focus", "blur", "offerPositionAdded"]);

const productInputEl = ref<typeof SingleProductInput | null>(null);
const variantInputEl = ref<HTMLInputElement | null>(null);
const amountInputEl = ref<typeof NumberInput | null>(null);

const product = ref(props.position?.product || null);
const amount = ref(
  props.position ? props.position.amount : props.group.boqAmount || 1
);
const unit = ref(props.position?.unit || props.group.boqUnit);
const notes = ref(props.position?.notes || "");
const isAlternative = ref(props.position?.isAlternative || false);
const variant = ref(props.position?.variant || null);

watch(
  () => props.position,
  (position) => {
    product.value = position?.product || null;
    amount.value = position ? position.amount : props.group.boqAmount || 1;
    unit.value = position?.unit || props.group.boqUnit;
    notes.value = position?.notes || "";
    isAlternative.value = position?.isAlternative || false;
    variant.value = position?.variant || null;
  }
);

const unitChoices = computed(() => {
  const product = props.position?.product || null;
  if (!product) return [];
  return [product.unit, ...product.alternativeUnits];
});

defineExpose({
  select: () => {
    if (variantInputEl.value) {
      variantInputEl.value.focus();
    } else if (amountInputEl.value) {
      amountInputEl.value.focus();
    }
  },
});

const addOfferPositionIfProductSelected = async () => {
  if (props.position) return;
  if (!product.value) return;

  const [newAmount, newUnit] = getAmountAndUnitForNewOfferPosition(
    props.group.boqAmount,
    props.group.boqUnit,
    product.value
  );
  unit.value = newUnit;
  amount.value = newAmount;

  addOfferPosition(props.group.id, {
    product: product.value,
    amount: newAmount,
    unit: newUnit,
    variant: variant.value,
    isAlternative: isAlternative.value,
    notes: notes.value,
  });

  resetOfferPositionContainer();

  emit("offerPositionAdded");
};
watch(product, addOfferPositionIfProductSelected);
watch(product, async () => {
  await nextTick();
  if (variantInputEl.value) {
    variantInputEl.value.focus();
  }
});

watch(
  () => [
    product.value,
    amount.value,
    unit.value,
    variant.value,
    isAlternative.value,
    notes.value,
  ],
  update
);

function resetOfferPositionContainer() {
  product.value = null;
  amount.value = props.group.boqAmount;
  unit.value = null;
  notes.value = "";
  variant.value = null;
  isAlternative.value = false;
}

async function update() {
  if (!props.position) {
    return;
  }
  const data = getChangedData();
  await updateOfferPosition(props.group.id, props.position.id, data);
}

function getChangedData() {
  if (!props.position) throw new Error("No position to get changed data from");
  const result = {} as Partial<OfferPosition>;

  const changedProduct = getChangedProduct();
  if (changedProduct) {
    result.product = changedProduct;
  }

  const changedUnit = getChangedUnitFromProductChange();
  if (changedUnit) {
    result.unit = changedUnit;
  }

  if (unit.value !== props.position.unit) {
    result.unit = unit.value || undefined;
  }

  const changedAmount = getNewAmountAfterUnitChange(result);
  if (changedAmount !== undefined) {
    result.amount = changedAmount;
  }

  if (amount.value !== props.position.amount) {
    result.amount = amount.value;
  }

  if (variant.value !== props.position.variant) {
    result.variant = variant.value;
  }

  if (isAlternative.value !== props.position.isAlternative) {
    result.isAlternative = isAlternative.value;
  }

  if (notes.value !== props.position.notes) {
    result.notes = notes.value;
  }
  if (result.product) result.productConfidence = 1;

  return result;
}

function getChangedProduct(): Product | undefined {
  if (!props.position) throw new Error("No position to get changed data from");

  if (!product.value) {
    return;
  }
  if (product.value === props.position.product) {
    return;
  }
  return product.value || undefined;
}

function getChangedUnitFromProductChange(): Unit | undefined {
  if (!product.value || !props.position) {
    return;
  }

  // Keep the unit if the products have the same unit
  if (product.value.unit === props.position.unit) return;

  // Keep the unit if the product has the currently selected unit as alternative unit
  if (product.value.alternativeUnits.includes(props.position.unit)) {
    return;
  }

  // Otherwise, switch to BOQ unit if possible
  if (props.group.boqUnit && product.value.unit === props.group.boqUnit) {
    return props.group.boqUnit;
  }

  // Otherwise, switch to product unit
  return product.value.unit;
}

function getNewAmountAfterUnitChange(
  result: Partial<OfferPosition>
): number | null | undefined {
  if (!props.position) throw new Error("No position to get changed data from");

  if (!props.position.amount) return;

  if (!result.unit) return;

  if (result.unit === props.position.unit) return; // Unit doesn't change -> amount doesn't change

  const newProduct = result.product || props.position.product;

  const convertedAmount = convertAmount(
    props.position.amount,
    props.position.unit,
    result.unit,
    newProduct
  );

  // If the conversion fails, keep the amount
  return convertedAmount == null ? undefined : convertedAmount;
}
</script>

<style scoped lang="scss">
.product-input-with-variant {
  max-width: 25%;
}

.first-row {
  > :not(:last-child) {
    margin-right: 8px !important;
  }
}

.amount-input {
  width: 60px;
}

.notes-badge {
  font-size: 10px;
}

.checkbox-margin-right {
  margin-right: 32px;
}
</style>
