<template>
  <div class="header-bar">
    <div class="header-bar-left row items-center no-wrap">
      <SidebarToggleButton
        v-if="!secondary && !isSidebarOpen"
        color="neutral-8"
      />
      <BreadcrumbsNavigation v-if="!secondary" />
      <slot />
    </div>
    <q-space />
    <div class="header-bar-right row items-center">
      <slot name="right" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSidebarStateStore } from "@/stores/sidebarState";
import { storeToRefs } from "pinia";
import SidebarToggleButton from "../Sidebar/SidebarToggleButton.vue";
import BreadcrumbsNavigation from "./BreadcrumbsNavigation.vue";

withDefaults(
  defineProps<{
    secondary?: boolean;
  }>(),
  {
    secondary: false,
  }
);

const { isOpen: isSidebarOpen } = storeToRefs(useSidebarStateStore());
</script>

<style lang="scss">
.header-bar {
  height: $header-bar-height !important;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $page-background;
  border-bottom: 1px solid $separator-color;
  color: $font-color;
  padding: 0 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;

  .header-bar-left {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 1;
    min-width: 1px;

    > * {
      margin-right: 0.5rem;
    }
  }

  .header-bar-right {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    > * {
      margin-left: 0.5rem;
    }
  }
}
</style>
