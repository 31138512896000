import { setupApp } from "@/setup";
import * as Sentry from "@sentry/vue";
import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://7a32dae616c572505422f6b479d28ba6@o4507741312909312.ingest.de.sentry.io/4507741770154064",
  environment: getEnvironment(),
  tracePropagationTargets: ["localhost", "kinisto.com", /^\//],
  integrations: [
    Sentry.browserTracingIntegration({
      router,
    }),
    Sentry.captureConsoleIntegration({ levels: ["error"] }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 0.5,
  profilesSampleRate: 0.5,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
  enabled: getEnvironment() !== "local",
  ignoreErrors: [
    "Request failed with status code 401",
    "Request failed with status code 403",
    "Request failed with status code 404",
    "Request aborted",
    "Network Error",
    "Failed to execute 'getComputedStyle' on 'Window': parameter 1 is not of type 'Element'",
  ],
});

function getEnvironment(): string {
  const hostname = window.location.hostname;
  if (hostname.includes("dev.")) {
    return "dev";
  } else if (hostname.includes(".review.")) {
    return "review";
  } else if (
    hostname === "localhost" ||
    hostname === "127.0.0.1" ||
    process.env.NODE_ENV === "development"
  ) {
    return "local";
  } else {
    return "prod";
  }
}

setupApp(app);

app.mount("#app");
