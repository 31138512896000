<template>
  <q-layout view="lHh lpR fFf">
    <settings-sidebar />
    <q-page-container class="page-container">
      <div class="page-container-inner">
        <div class="page shadow-1">
          <router-view />
        </div>
      </div>
    </q-page-container>
  </q-layout>
</template>

<script setup lang="ts">
import SettingsSidebar from "@/components/Settings/SettingsSidebar.vue";
import { useCurrentUserStore } from "@/stores/currentUser";

async function hydrateUserStore() {
  const userStore = useCurrentUserStore();
  await userStore.loadUser();
}
hydrateUserStore();
</script>

<style lang="scss">
.page-container {
  background-color: $app-background;
  height: calc(100vh);

  .page-container-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;

    .page {
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: $page-background;
      border-radius: 4px;
    }
  }
}
</style>
