<template>
  <div class="row items-center gap-x-sm">
    <q-chip
      v-if="filterStatusStrings.length > 0"
      size="sm"
      class="filter-chip"
      square
      outline
      color="neutral-7"
      icon="sym_r_assignment"
      removable
      @remove="$emit('update:filterStatusStrings', [])"
    >
      {{ t("statuses", filterStatusStrings.length) }}
    </q-chip>
    <q-chip
      v-if="filterTagsIds.length > 0"
      size="sm"
      class="filter-chip"
      square
      outline
      color="neutral-7"
      icon="sym_r_sell"
      removable
      @remove="$emit('update:filterTagsIds', [])"
    >
      {{ t("tags", filterTagsIds.length) }}
    </q-chip>
    <q-chip
      v-if="filterUserIds.length > 0"
      size="sm"
      class="filter-chip"
      square
      outline
      color="neutral-7"
      icon="sym_r_person"
      removable
      @remove="$emit('update:filterUserIds', [])"
    >
      {{ t("users", filterUserIds.length) }}
    </q-chip>
    <q-chip
      v-if="filterSender"
      size="sm"
      class="filter-chip"
      square
      outline
      color="neutral-7"
      icon="sym_r_alternate_email"
      removable
      @remove="$emit('update:filterSender', null)"
    >
      {{ t("Sender") }}: {{ filterSender }}
    </q-chip>
    <q-chip
      v-if="filterBuildingProject"
      size="sm"
      class="filter-chip"
      square
      outline
      color="neutral-7"
      icon="sym_r_apartment"
      removable
      @remove="$emit('update:filterBuildingProject', null)"
    >
      {{ t("Building project") }}: {{ filterBuildingProject }}
    </q-chip>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

defineProps<{
  filterStatusStrings: string[];
  filterTagsIds: number[];
  filterUserIds: (number | null)[];
  filterSender: string | null;
  filterBuildingProject: string | null;
}>();

defineEmits<{
  "update:filterStatusStrings": [statuses: string[]];
  "update:filterUserIds": [userIds: (number | null)[]];
  "update:filterSender": [sender: string | null];
  "update:filterBuildingProject": [sender: string | null];
  "update:filterTagsIds": [tagIds: number[]];
}>();

const { t } = useI18n();
</script>

<style scoped lang="scss">
.filter-chip {
  margin: 0;
}
</style>
