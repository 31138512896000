import * as api from "@/api/notification";
import { useRequestCancellation } from "@/composables/useRequestCancellation";
import { useRouteParams } from "@/composables/useRouteParams";
import type { Notification } from "@/types/notification";
import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";
import { ref, watch } from "vue";

const PAGE_SIZE = 50;
const POLLING_INTERVAL_MS = 5000;

export const useNotificationsStore = defineStore("notifications", () => {
  const { organizationId } = useRouteParams();

  const unreadCount = ref(0);
  const notifications = ref<Map<number, Notification>>(new Map());
  const nextCursor = ref<string | null>(null);

  watch(organizationId, () => {
    unreadCount.value = 0;
    notifications.value = new Map();
    nextCursor.value = null;

    updateUnreadCount();
    loadNotifications();
  });

  const { cancelsLoading, openLoadingRequests } = useRequestCancellation();

  async function updateUnreadCount() {
    const requestId = crypto.randomUUID();
    let isCancelled = false;
    openLoadingRequests[requestId] = () => {
      isCancelled = true;
    };
    try {
      const result = await api.getUnreadNotificationCount(organizationId.value);
      if (!isCancelled) {
        unreadCount.value = result;
      }
    } finally {
      delete openLoadingRequests[requestId];
    }
  }

  async function pollUnreadCount() {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      await updateUnreadCount();
      await new Promise((resolve) => setTimeout(resolve, POLLING_INTERVAL_MS));
    }
  }
  pollUnreadCount();

  async function loadNotifications() {
    const response = await api.listNotifications(
      organizationId.value,
      PAGE_SIZE,
      nextCursor.value
    );
    updateNotifications(response.results);
    nextCursor.value = response.next;

    return response;
  }

  function updateNotifications(newNotifications: Notification[]) {
    newNotifications.forEach((notification) => {
      notifications.value.set(notification.id, notification);
    });
    const sortedNotifications = Array.from(notifications.value.values()).sort(
      (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
    );
    notifications.value = new Map(sortedNotifications.map((n) => [n.id, n]));
  }

  async function baseMarkAllNotificationsAsRead() {
    await api.markAllNotificationsAsRead(organizationId.value);
    notifications.value.forEach((notification) => {
      notification.isRead = true;
    });
    unreadCount.value = 0;
  }

  const markAllNotificationsAsRead = cancelsLoading(
    baseMarkAllNotificationsAsRead
  );

  async function baseMarkNotificationAsRead(notification: Notification) {
    await api.markNotificationAsRead(organizationId.value, notification.id);
    notification.isRead = true;
    unreadCount.value -= 1;
    if (unreadCount.value < 0) {
      Sentry.captureMessage("Unread notifications counter is less than 0!");
      unreadCount.value = 0;
    }
  }

  const markNotificationAsRead = cancelsLoading(baseMarkNotificationAsRead);

  async function baseMarkNotificationAsUnread(notification: Notification) {
    await api.markNotificationAsUnread(organizationId.value, notification.id);
    notification.isRead = false;
    unreadCount.value += 1;
  }

  const markNotificationAsUnread = cancelsLoading(baseMarkNotificationAsUnread);

  async function toggleNotificationReadStatus(notification: Notification) {
    if (notification.isRead) {
      await markNotificationAsUnread(notification);
    } else {
      await markNotificationAsRead(notification);
    }
  }

  return {
    updateUnreadCount,
    loadNotifications,
    markAllNotificationsAsRead,
    markNotificationAsRead,
    markNotificationAsUnread,
    toggleNotificationReadStatus,
    unreadCount,
    notifications,
  };
});
