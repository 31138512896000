<template>
  <q-page class="fit" :style-fn="() => ''">
    <header-bar>
      <products-search-bar v-model="searchTerm" class="q-ml-md" />
    </header-bar>
    <products-table
      square
      class="col page-below-header-bar"
      :is-loading="isLoading"
      :products="products"
      :page-size="pageSize"
      :page="page"
      :total="total"
      :search-text="searchTerm || ''"
      @next-page="nextPage"
      @previous-page="previousPage"
      @request="handleTableRequest"
    />
  </q-page>
</template>

<script setup lang="ts">
import * as api from "@/api/product";
import HeaderBar from "@/components/Header/HeaderBar.vue";
import ProductsSearchBar from "@/components/ProductsPage/ProductsSearchBar.vue";
import ProductsTable from "@/components/ProductsPage/ProductsTable.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import type { Product } from "@/types/product";
import type { QTableProps } from "quasar";
import { onMounted, ref, watch } from "vue";

const searchTerm = ref<string | null>(null);
const isLoading = ref(false);
const products = ref<Product[]>([]);
const page = ref(1);
const pageSize = ref(50);
const nextCursor = ref<string | null>(null);
const previousCursor = ref<string | null>(null);
const total = ref(0);

const { organizationId } = useRouteParams();

watch(searchTerm, () => {
  resetPagination();
  loadData();
});

function nextPage() {
  if (nextCursor.value) {
    page.value++;
    loadData(nextCursor.value);
  }
}

function previousPage() {
  if (previousCursor.value) {
    page.value--;
    loadData(previousCursor.value);
  }
}

function resetPagination() {
  nextCursor.value = null;
  previousCursor.value = null;
  page.value = 1;
}

function handleTableRequest(request: {
  pagination: QTableProps["pagination"];
}) {
  const { pagination } = request;
  if (pagination === undefined) return;

  if (
    pagination.rowsPerPage !== undefined &&
    pagination.rowsPerPage !== pageSize.value
  ) {
    handlePageSizeUpdate(pagination.rowsPerPage);
    return;
  }

  if (pagination.page !== undefined) handlePageUpdate(pagination.page);
}

function handlePageSizeUpdate(newPageSize: number) {
  pageSize.value = newPageSize;
  resetPagination();
  loadData();
}

function handlePageUpdate(newPage: number) {
  if (newPage > page.value && nextCursor.value) {
    page.value++;
    loadData(nextCursor.value);
  } else if (newPage < page.value && previousCursor.value) {
    page.value--;
    loadData(previousCursor.value);
  }
}

async function loadData(cursor: string | null = null) {
  isLoading.value = true;

  try {
    const result = await api.listProducts(
      organizationId.value,
      searchTerm.value,
      cursor,
      pageSize.value
    );
    nextCursor.value = result.next;
    previousCursor.value = result.previous;
    total.value = result.count;
    products.value = result.results;
  } finally {
    isLoading.value = false;
  }
}
onMounted(loadData);
</script>
