<template>
  <q-input
    :rules="[isNotEmpty]"
    class="q-mt-md"
    dense
    outlined
    name="hostname"
    v-model="hostname"
    :label="$t('Hostname')"
    @update:model-value="updateConfiguration"
  />
  <q-input
    :rules="[isNotEmpty]"
    class="q-mt-md"
    type="number"
    dense
    outlined
    name="port"
    v-model="port"
    :label="$t('Port')"
    @update:model-value="updateConfiguration"
  />
  <q-input
    :rules="[isNotEmpty]"
    class="q-mt-md"
    dense
    outlined
    name="username"
    v-model="username"
    :label="$t('Username')"
    @update:model-value="updateConfiguration"
  />
  <q-input
    class="q-mt-md"
    dense
    outlined
    name="privateKey"
    v-model="privateKey"
    :label="$t('Private key')"
    type="textarea"
    @update:model-value="updateConfiguration"
    autocomplete="off"
  />
  <q-input
    class="q-mt-md"
    dense
    outlined
    name="password"
    v-model="password"
    :label="$t('Password')"
    @update:model-value="updateConfiguration"
    autocomplete="off"
  />
  <q-input
    class="q-mt-md"
    dense
    outlined
    name="directoryOut"
    v-model="directoryOut"
    :label="$t('Directory outgoing orders')"
    @update:model-value="updateConfiguration"
  />
  <q-input
    class="q-mt-md"
    dense
    outlined
    name="directoryIn"
    v-model="directoryIn"
    :label="$t('Directory incoming aleaud')"
    @update:model-value="updateConfiguration"
  />
  <q-checkbox
    class="q-mt-md"
    dense
    outlined
    name="deleteAleaudFiles"
    v-model="deleteAleaudFiles"
    :label="$t('Delete aleaud files')"
    @update:model-value="updateConfiguration"
  />
  <q-input
    class="q-mt-md"
    dense
    outlined
    name="sndprn"
    v-model="sndprn"
    :label="$t('IDOC Field SNDPRN')"
    @update:model-value="updateConfiguration"
  />
</template>
<script setup lang="ts">
import { ref } from "vue";
import type { keyable } from "@/types/organization";
import { useFormValidationRules } from "@/composables/formValidation/useFormValidationRules";

const props = defineProps<{
  configuration: keyable;
}>();

const hostname = ref<string>(props.configuration.hostname);
const port = ref<number>(props.configuration.port);
const username = ref<string>(props.configuration.username);
const privateKey = ref<string>(props.configuration.privateKey);
const password = ref<string>(props.configuration.password);
const directoryOut = ref<string>(props.configuration.directoryOut);
const directoryIn = ref<string>(props.configuration.directoryIn);
const sndprn = ref<string>(props.configuration.sndprn);
const idocFlavor = ref<string>(props.configuration.idocFlavor);
const deleteAleaudFiles = ref<boolean>(
  Boolean(props.configuration.deleteAleaudFiles)
);
const { isNotEmpty } = useFormValidationRules();

const emits = defineEmits<{
  "update:configuration": [configuration: keyable];
}>();

const updateConfiguration = () => {
  emits("update:configuration", {
    hostname: hostname.value,
    port: port.value,
    username: username.value,
    privateKey: privateKey.value || null,
    password: password.value || null,
    directoryOut: directoryOut.value,
    directoryIn: directoryIn.value,
    sndprn: sndprn.value,
    idocFlavor: idocFlavor.value,
  });
};
</script>

<style scoped lang="scss"></style>
