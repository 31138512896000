<template>
  <q-menu :offset="[0, 4]">
    <q-card>
      <div class="q-px-sm">
        <q-input
          borderless
          dense
          :placeholder="props.searchPlaceholder"
          v-model="searchText"
          autofocus
        />
      </div>
      <q-separator />
      <div>
        <q-list>
          <template
            v-for="option in filteredOptions.slice(
              0,
              props.maxOptionsDisplayed
            )"
            :key="option[idValue]"
          >
            <slot name="option" :option="option" :select="select">
              <q-item
                dense
                clickable
                v-ripple
                v-close-popup
                @click="select(option)"
                :class="isSelectedOption(option) && 'bg-neutral-4'"
              >
                <q-item-section>
                  <slot name="option-label" :option="option">
                    {{ props.labelFn(option) }}
                  </slot>
                </q-item-section>
              </q-item>
            </slot>
          </template>
        </q-list>
      </div>
    </q-card>
  </q-menu>
</template>

<script
  setup
  lang="ts"
  generic="IdValue extends string, SearchKey extends string, Option extends { [key in SearchKey]: string } & {[key in IdValue]: string | number;}"
>
import { computed, ref } from "vue";

const props = withDefaults(
  defineProps<{
    options: Option[];
    selectedOption: Option | null;
    searchPlaceholder: string;
    idValue: IdValue;
    searchValues: SearchKey[];
    labelFn: (option: Option) => string;
    maxOptionsDisplayed?: number;
  }>(),
  { maxOptionsDisplayed: 10 }
);

const emit = defineEmits<{
  select: [option: Option];
}>();

const searchText = ref<string | null>(null);

const filteredOptions = computed(() => {
  if (!searchText.value) {
    return props.options;
  }
  return props.options.filter((option) =>
    props.searchValues.some((value) =>
      option[value].toLowerCase().includes(searchText.value!.toLowerCase())
    )
  );
});

function isSelectedOption(option: Option) {
  return (
    props.selectedOption &&
    option[props.idValue] === props.selectedOption[props.idValue]
  );
}

function select(option: Option) {
  emit("select", option);
  searchText.value = null;
}
</script>
