import { defineStore } from "pinia";
import { persistentRef } from "@/utils/persistentRef";

export const useSidebarStateStore = defineStore("sidebarState", () => {
  const isOpen = persistentRef("is-sidebar-open", true);

  function toggle() {
    isOpen.value = !isOpen.value;
  }

  return {
    isOpen,
    toggle,
  };
});
