<template>
  <div class="unit-select" :class="{ hasChoices }">
    <div>{{ selected && $t(`productUnit.${selected}`) }}</div>
    <q-menu
      v-if="hasChoices"
      fit
      anchor="bottom left"
      self="top left"
      :offset="[0, 2]"
      auto-close
    >
      <q-list dense separator class="unit-select-menu">
        <q-item
          v-for="choice in choices"
          :key="choice"
          clickable
          @click="$emit('update:selected', choice)"
          :active="choice === selected"
        >
          <q-item-section>{{ $t(`productUnit.${choice}`) }}</q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </div>
</template>

<script setup lang="ts">
import type { Unit } from "@/types/product";
import { computed } from "vue";

const props = defineProps<{
  selected: Unit | null;
  choices: Unit[];
}>();

defineEmits(["update:selected"]);

const hasChoices = computed(() => props.choices.length > 0);
</script>

<style scoped lang="scss">
.unit-select {
  border: 1px solid $neutral-5;
  border-radius: 4px;
  height: 24px;
  background-color: $white;
  color: $neutral-7;
  cursor: not-allowed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  flex-wrap: nowrap;
  padding: 0 2px;
  min-width: 60px;
  text-align: right;

  &.hasChoices {
    cursor: pointer;
    color: $neutral-10;
  }
}

.unit-select-menu {
  font-size: smaller;
}
</style>
