import { i18n } from "@/i18n";
import { createPinia } from "pinia";
import { Dialog, Loading, Notify, Quasar } from "quasar";
import langDe from "quasar/lang/de";
import type { App } from "vue";
import iconSet from "quasar/icon-set/material-symbols-rounded";

// Import CSS animations
import "@quasar/extras/animate/pulse.css";

// Import icon libraries
import "@quasar/extras/material-symbols-rounded/material-symbols-rounded.css";

// Import Quasar css
import "quasar/src/css/index.sass";

import router from "./router";

export function setupApp(app: App) {
  app.use(Quasar, {
    plugins: { Dialog, Notify, Loading },
    lang: langDe,
    iconSet,
  });

  app.use(createPinia());
  app.use(router);
  app.use(i18n);
}
