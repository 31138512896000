<template>
  <q-expansion-item v-model="isExpanded" dense class="full-width">
    <template v-slot:header>
      <q-item-section>
        <div class="row no-wrap">
          <div class="sidebar-menu-item-avatar">
            <q-icon name="sym_r_inbox" size="20px" />
          </div>
          <div class="sidebar-menu-item-label" :title="inbox.name">
            {{ inbox.name }}
          </div>
        </div>
      </q-item-section>
    </template>
    <sidebar-menu-item
      v-for="inboxView in inboxViews"
      :key="inboxView.label"
      :to="inboxView.link.value"
      :label="$t(`inboxView_${inboxView.label}`)"
      :is-active="
        () => isInboxViewActive(inboxView.link.value.params.inboxView)
      "
    />
  </q-expansion-item>
</template>

<script setup lang="ts">
import { useInboxViews } from "@/composables/useInboxViews";
import { useRouteParams } from "@/composables/useRouteParams";
import type { ShortInbox } from "@/types/inbox";
import { persistentRef } from "@/utils/persistentRef";
import { computed, watch } from "vue";
import { useRouter } from "vue-router";
import SidebarMenuItem from "./SidebarMenuItem.vue";

const props = defineProps<{
  inbox: ShortInbox;
}>();

const { getInboxViews } = useInboxViews();
const inboxViews = computed(() => getInboxViews(props.inbox));

const { organizationId } = useRouteParams();

const { currentRoute } = useRouter();

const isInboxActive = computed(() => {
  return currentRoute.value.path.startsWith(
    `/organizations/${organizationId.value}/inboxes/${props.inbox.id}/`
  );
});

const isExpanded = persistentRef(
  `sidebar-inbox-${props.inbox.id}-expanded`,
  isInboxActive.value
);
watch(isInboxActive, () => {
  if (isInboxActive.value) {
    isExpanded.value = true;
  }
});

function isInboxViewActive(inboxViewName: string) {
  return (
    isInboxActive.value && currentRoute.value.params.inboxView === inboxViewName
  );
}
</script>

<style lang="scss">
.sidebar-menu-item-label {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.sidebar-menu-item-avatar {
  width: 34px;
}
</style>
