<template>
  <div class="slim-page">
    <settings-page-header
      section="organization"
      page="productImportConfiguration"
    />
    <q-form @submit="saveProductImportConfiguration" class="q-pa-md">
      <q-checkbox
        v-model="isEnabled"
        :label="
          $t('settings.organization.productImportConfiguration.isEnabled')
        "
      />
      <div v-if="isEnabled">
        <div>
          <q-select
            class="q-mt-md"
            v-if="!isLoading"
            dense
            outlined
            v-model="importFunction"
            :readonly="!canWrite"
            :options="ImportFunctionOptions"
            :label="
              $t(
                'settings.organization.productImportConfiguration.importFunction'
              )
            "
            map-options
          />
          <q-skeleton type="QInput" style="height: 129px" v-else />
        </div>
        <sftp-idoc-data-import-configuration
          v-if="importFunction === ImportFunctionType.SFTP_IDOC"
          :configuration="configuration"
          type="product"
          @update:configuration="configuration = $event"
        />
      </div>
      <q-btn
        v-if="canSubmit"
        dense
        class="q-mt-md float-right"
        type="submit"
        color="primary"
        :label="$t('settings.organization.productImportConfiguration.save')"
        :disable="isLoading"
        :loading="isLoading"
      />
    </q-form>
  </div>
</template>

<script setup lang="ts">
import { getProductImport, setProductImport } from "@/api/organization";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import SftpIdocDataImportConfiguration from "@/pages/Settings/Organization/Configurations/SftpIdocDataImportConfiguration.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentPermissionsStore } from "@/stores/currentPermissions";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  ImportFunctionType,
  type keyable,
  ImportFunctionOptions,
} from "@/types/organization";

const isEnabled = ref<boolean>(false);
const importFunction = ref<ImportFunctionType | null>(null);

const configuration = ref<keyable>({});
const isLoading = ref(true);

const { organizationId } = useRouteParams();

const { hasCurrentOrganizationRole } = storeToRefs(
  useCurrentPermissionsStore()
);
const canWrite = computed(
  () => hasCurrentOrganizationRole.value["organization_admin"]
);

const canSubmit = computed(() => {
  if (!canWrite.value) return false;
  return importFunction.value !== null;
});

const q = useQuasar();
const { t } = useI18n();

async function loadDataImportConfiguration() {
  isLoading.value = true;
  try {
    const i = await getProductImport(organizationId.value);
    importFunction.value = i.importFunction;
    configuration.value = i.configuration;
    isEnabled.value = i.isEnabled;
  } finally {
    isLoading.value = false;
  }
}
onMounted(loadDataImportConfiguration);

async function saveProductImportConfiguration() {
  isLoading.value = true;

  try {
    await setProductImport(organizationId.value, {
      importFunction: importFunction.value!,
      configuration: configuration.value,
      isEnabled: isEnabled.value,
    });
    q.notify({
      message: t(
        `settings.organization.productImportConfiguration.notifySaved`
      ),
      color: "positive",
    });
  } catch (e: any) {
    const errorMessage = Object.entries(e.response.data)
      .map(([key, value]) => {
        const errorsList = value as string[];
        const fieldError = errorsList.map(t).join(" ");
        return `${t(key)}: ${fieldError}`;
      })
      .join("\n\n");
    q.notify({
      message: errorMessage,
      color: "negative",
    });
  } finally {
    isLoading.value = false;
  }
}
</script>
