<template>
  <router-link :to="to" class="sidebar-menu-item-router-link">
    <q-item
      dense
      clickable
      v-ripple
      :class="{
        'active-sidebar-menu-item': showActive,
      }"
      v-bind="$attrs"
    >
      <q-item-section>
        <div class="row no-wrap">
          <div class="sidebar-menu-item-avatar row items-center no-wrap">
            <slot name="avatar" />
          </div>
          <div class="sidebar-menu-item-label" :title="label">
            {{ label }}
          </div>
          <q-space />
          <q-badge v-if="unreadCount" color="neutral-4" text-color="neutral-10">
            {{ unreadCount }}
          </q-badge>
        </div>
      </q-item-section>
    </q-item>
  </router-link>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useLink, useRouter, type RouteLocationRaw } from "vue-router";

const props = defineProps<{
  to: RouteLocationRaw;
  label: string;
  isActive?: (currentRoute: RouteLocationRaw) => boolean;
  unreadCount?: number;
}>();

const { currentRoute } = useRouter();

const showActive = computed(() => {
  if (props.isActive) {
    return props.isActive(currentRoute.value);
  }
  return useLink({ to: props.to }).isActive.value;
});
</script>

<style lang="scss">
.sidebar-menu-item-router-link {
  text-decoration: inherit;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.active-sidebar-menu-item {
  background-color: $neutral-4;

  &.white-bg {
    background-color: $neutral-4;
  }
}
</style>
