<template>
  <div
    :class="{ 'multi-product-input': true, disabled, isActive }"
    @focus="focus"
    @blur="blur"
    :tabindex="isActive ? -1 : 0"
    @click.stop.prevent
  >
    <div class="count-indicator">
      {{ positionsWithProduct.length }}
    </div>
    <div class="main-container">
      <transition-group name="chip" tag="div" class="chips-container">
        <product-chip
          v-for="position in positionsWithProduct"
          :key="position.temporaryId || position.id"
          :product="position.product"
          :is-ai-suggestion="position.isAiSuggestion"
          :confidence="position.productConfidence"
        />
      </transition-group>
      <input
        v-if="isActive && !disabled"
        v-model="inputText"
        @blur="blurIfNoVariantSelected"
        ref="inputEl"
        @keydown.esc.stop.prevent="handleEscape"
        @keydown.backspace="handleBackspace"
        @keydown.enter.stop
      />
    </div>
    <product-search-menu
      :offer-position-group="offerPositionGroup"
      :searchText="inputText"
      :isActive="isActive && !disabled"
      @select="addProduct"
    />
    <product-variant-edit
      v-if="!disabled"
      :show="showProductVariantEdit"
      :product="selectedProduct"
      @select-variant="(variant) => addProductWithVariant(variant)"
      @cancel="showProductVariantEdit = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import type { OfferPosition } from "@/types/offerPosition";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import type { Product } from "@/types/product";
import { computed, nextTick, ref } from "vue";
import ProductChip from "./ProductChip.vue";
import ProductSearchMenu from "./ProductSearchMenu.vue";
import ProductVariantEdit from "./ProductVariantEdit.vue";
import { getAmountAndUnitForNewOfferPosition } from "@/utils/productUnitAmount";

const isActive = ref(false);

const inputEl = ref<HTMLInputElement | null>(null);
const inputText = ref("");

const props = defineProps<{
  offerPositionGroup: OfferPositionGroup;
  disabled?: boolean;
}>();

const positionsWithProduct = computed(
  () =>
    props.offerPositionGroup.offerPositions.filter(
      (p) => p.product
    ) as (OfferPosition & { product: Product })[]
);

const emit = defineEmits<{
  focus: [];
  blur: [];
  expand: [];
  escape: [];
  "first-product-added": [];
}>();

async function focus() {
  if (isActive.value || props.disabled) return;
  isActive.value = true;
  emit("focus");
  await nextTick();
  inputEl.value?.focus();

  // The cursor can be lost due to new pdf pages being rendered, even if the focus is still on the
  // element 🤯. Let's get it back.
  for (let i = 0; i < 3; i++) {
    await new Promise((resolve) => setTimeout(resolve, 100));
    if (document.activeElement === inputEl.value) inputEl.value?.select();
  }
}

async function blur() {
  await nextTick();
  if (!isActive.value) return;
  // don't blur if the inner input is focused
  // this is necessary because the input can be blurred by the parent component being selected
  if (inputEl.value && document.activeElement === inputEl.value) return;
  isActive.value = false;
  inputText.value = "";
  emit("blur");
}

async function forceBlur() {
  isActive.value = false;
  inputText.value = "";
  inputEl.value?.blur();
  emit("blur");
}

defineExpose({ focus, blur, forceBlur });

function handleBackspace() {
  if (
    inputEl.value?.selectionStart === 0 &&
    inputEl.value?.selectionEnd === 0
  ) {
    removeLastProduct();
  }
}

function handleEscape() {
  inputEl.value?.blur();
  emit("escape");
}

const { deleteOfferPosition, addOfferPosition } =
  useCurrentOfferPositionGroupsStore();

async function removeLastProduct() {
  if (positionsWithProduct.value.length === 0) return;
  const lastProductOfferPosition =
    positionsWithProduct.value[positionsWithProduct.value.length - 1];
  await deleteOfferPosition(
    props.offerPositionGroup.id,
    lastProductOfferPosition.id
  );
}

async function addProduct(product: Product) {
  if (product.hasVariant) {
    selectedProduct.value = product;
    // Clear the input text to hide the product selection menu and show the variant edit
    inputText.value = "";
    await nextTick();
    showProductVariantEdit.value = true;
    return;
  }
  doAddProduct(product, null);
}

const showProductVariantEdit = ref(false);
const selectedProduct = ref<Product | null>(null);

function addProductWithVariant(variant: string) {
  if (!selectedProduct.value) return;
  showProductVariantEdit.value = false;
  doAddProduct(selectedProduct.value, variant);
}

async function doAddProduct(product: Product, variant: string | null) {
  if (props.offerPositionGroup.offerPositions.length === 0) {
    emit("first-product-added");
  }

  const [amount, unit] = getAmountAndUnitForNewOfferPosition(
    props.offerPositionGroup.boqAmount,
    props.offerPositionGroup.boqUnit,
    product
  );

  await addOfferPosition(props.offerPositionGroup.id, {
    product,
    variant,
    unit,
    amount,
  });
  inputText.value = "";
  inputEl.value?.focus();
}

function blurIfNoVariantSelected() {
  if (showProductVariantEdit.value) return;
  blur();
}
</script>

<style scoped lang="scss">
.multi-product-input {
  cursor: text;
  background-color: white;
  border: 1px solid $neutral-5;
  height: 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  .count-indicator {
    padding: 2px 6px;
    border-right: 1px solid $neutral-5;
    color: $neutral-8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    padding: 1px 2px;

    .chips-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      > :not(:last-child) {
        margin-right: 4px;
      }
    }

    > input {
      border: none;
      outline: none !important;
      background-color: transparent;
      flex: 1;
      padding: 0;
      margin: 0;
      margin-left: 2px;

      &:focus-visible {
        outline: none !important;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    background-color: $neutral-1;
    border-color: $neutral-4;

    .count-indicator {
      border-color: $neutral-4;
      color: $neutral-4;
    }
  }

  &:hover {
    border-color: $neutral-9;
  }

  &.isActive {
    border-color: $neutral-9;
    border-width: 2px;
    outline: none;
  }
}

/* Chip enter animation */
.chip-enter-active,
.chip-leave-active {
  transition: all 0.15s ease;
}
.chip-enter-from,
.chip-leave-to {
  opacity: 0;
  transform: scale(0.5);
}
.chip-enter-to,
.chip-leave-from {
  opacity: 1;
  transform: scale(1);
}
</style>
