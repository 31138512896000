// Share of total page width to pad bounding boxes on each side E.g. 0.01 == 1% of total page width
export const PDF_HIGHLIGHT_PAD_X = 0.002;
export const PDF_HIGHLIGHT_PAD_Y = 0.0002;

// If PDF highlight boxes overlap by this much on the y-axis (as a percentage of their combined
// height), they are combined into a single box. This is so that page highlights on the same line,
// e.g. adjacent words, don't appear as separate boxes
export const PDF_HIGHLIGHT_COMBINE_THRESHOLD_Y = 0.5;
// The maximum x-distance of same line highlights to combine them into a single box as a percentage
// of total page width
export const PDF_HIGHLIGHT_COMBINE_MAX_DISTANCE_X = 0.02;

// The default page size for the inquiry table in the inbox page
export const DEFAULT_INQUIRY_TABLE_PAGE_SIZE = 20;

// A short delay before clearing the previous inquiry when the route changes, to avoid flickering
export const CLEAR_PREVIOUS_INQUIRY_AFTER_MS = 200;

// The maximum time to wait for a group ref to be available before giving up
export const MAX_WAIT_FOR_GROUP_REF_MS = 200;

// The maximum time to wait for a PDF display element to be available before giving up
export const MAX_WAIT_FOR_PDF_DISPLAY_EL_MS = 2000;

// The maximum time to wait for a display highlight to be available before giving up
export const MAX_WAIT_FOR_DISPLAY_HIGHLIGHT_MS = 2000;

// The maximum number of products to show in the dropdown when search for a product in an offer
// position
export const MAX_NUM_PRODUCTS_SHOWN_IN_DROPDOWN = 10;
export const NUM_PRODUCTS_TO_RETRIEVE_IN_ADVANCE = 30;

export const MAX_NUM_CUSTOMERS_SHOWN_IN_DROPDOWN = 10;
export const NUM_CUSTOMERS_TO_RETRIEVE_IN_ADVANCE = 30;

export const WARN_IF_SUBMISSION_IS_IN_LESS_THAN_BUSINESS_DAYS = 2;

export const DEFAULT_TAG_COLOR = "#90cafa";
export const DEFAULT_CUSTOM_STATUS_COLOR = "#90cafa";
