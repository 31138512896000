import type { OfferPosition } from "@/types/offerPosition";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import { inject, provide, ref } from "vue";

const PRODUCT_VARIANT_CONFIGURATOR = Symbol("productVariantConfigurator");

interface ProductVariantConfiguratorInjection {
  showProductVariantConfigurator: (
    group: OfferPositionGroup,
    position: OfferPosition,
    variant: string
  ) => void;
}

export function provideProductVariantConfigurator() {
  const configuratorVariant = ref<string | null>(null);
  const configuredGroup = ref<OfferPositionGroup | null>(null);
  const configuredPosition = ref<OfferPosition | null>(null);
  const showConfigurator = ref(false);

  function showProductVariantConfigurator(
    group: OfferPositionGroup,
    position: OfferPosition,
    variant: string
  ) {
    configuratorVariant.value = variant;
    configuredGroup.value = group;
    configuredPosition.value = position;
    showConfigurator.value = true;
  }

  provide<ProductVariantConfiguratorInjection>(PRODUCT_VARIANT_CONFIGURATOR, {
    showProductVariantConfigurator,
  });

  return {
    configuratorVariant,
    configuredGroup,
    configuredPosition,
    showConfigurator,
  };
}

export function useProductVariantConfigurator() {
  const injection = inject<ProductVariantConfiguratorInjection>(
    PRODUCT_VARIANT_CONFIGURATOR
  );
  if (!injection) {
    throw new Error(
      "useProductVariantConfigurator must be used within a component that calls provideProductVariantConfigurator"
    );
  }
  const { showProductVariantConfigurator } = injection;

  return {
    showProductVariantConfigurator,
  };
}
