<template>
  <select-menu
    :selected-option="selectedUser"
    :options="options"
    :label-fn="(user) => user.email"
    :search-values="['email', 'lastName', 'firstName']"
    id-value="email"
    :search-placeholder="$t('Search user')"
    @select="select"
    @show="usersStore.refresh"
  >
    <template #option-label="{ option }">
      <user-display :user="option" />
    </template>
  </select-menu>
</template>

<script setup lang="ts">
import { useCurrentInboxUsersStore } from "@/stores/currentInboxUsers";
import { useCurrentUserStore } from "@/stores/currentUser";
import type { User, UserOrEmptyUser } from "@/types/user";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import SelectMenu from "@/components/SelectMenus/SelectMenu.vue";
import UserDisplay from "./UserDisplay.vue";

const props = defineProps<{
  user: User | null;
}>();

const emit = defineEmits<{
  "update:user": [user: User | null];
}>();

const { user: currentUser } = storeToRefs(useCurrentUserStore());
const usersStore = useCurrentInboxUsersStore();
const { users, usersWithEmptyUser } = storeToRefs(usersStore);
const { emptyUser } = usersStore;

const options = computed(() => {
  if (!currentUser.value) return usersWithEmptyUser.value;
  return [
    emptyUser,
    currentUser.value,
    ...users.value.filter((u) => u.email !== currentUser.value!.email),
  ];
});

const selectedUser = computed(() =>
  props.user === null ? usersStore.emptyUser : props.user
);

function select(user: UserOrEmptyUser) {
  if (!user.email.length || user.id === null) {
    // empty user
    emit("update:user", null);
  } else {
    emit("update:user", user as User);
  }
}
</script>
