<template>
  <div>
    <q-item
      ref="headerEl"
      dense
      clickable
      :v-ripple="!isActive"
      expand-icon-toggle
      :class="headerClass"
      :tabindex="0"
      @mousedown="$emit('headerclick', $event)"
      :active="false"
      :focused="false"
    >
      <q-item-section>
        <div class="row no-wrap full-width justify-start items-center">
          <toggle-offered-button
            :is-offered="group.isOffered"
            :disable="disabled"
            @toggle="$emit('setOffered', !group.isOffered)"
          />
          <div
            ref="referenceIdEl"
            class="positions-table-item-reference-id ellipsis q-mr-sm"
            style="outline: none"
            :class="{ 'cursor-text': group.isManuallyCreated }"
            :contenteditable="group.isManuallyCreated"
            @click="focusReferenceIdEl"
            @blur="handleReferenceIdElBlur"
            @keydown.left.stop
            @keydown.right.stop
            @keydown.a.stop
            @keydown.f.stop
          >
            {{ group.boqReferenceId }}
          </div>
          <div class="positions-table-item-short-text ellipsis col">
            {{ group.boqTextShort }}
          </div>
          <div class="positions-table-item-rest row no-wrap items-center">
            <multi-product-input
              ref="productInputEl"
              :offer-position-group="group"
              :disabled="disabled"
              class="positions-table-item-quick-input ellipsis"
              @focus="isActive = true"
              @blur="isActive = false"
              @escape="productInputEl?.blur()"
              @expand="
                isExpanded = true;
                nextTick(() => amountMultiplesInput?.focus());
              "
              @mousedown.stop="$emit('activate')"
              @keydown.stop.a
              @keydown.stop.f
              @keydown.stop.left
              @keydown.stop.right
              @keydown.stop.escape="productInputEl?.blur()"
              :tabindex="0"
            />
            <div
              class="positions-table-item-amount q-mx-sm"
              v-if="!group.isManuallyCreated"
            >
              {{ group.boqAmount }}
              {{ group.boqUnit?.length && $t(`productUnit.${group.boqUnit}`) }}
            </div>
            <div class="q-ml-lg" v-else />
            <div class="col-auto">
              <q-checkbox
                dense
                size="sm"
                v-model="isCompletedCheckbox"
                color="neutral-7"
                :disable="disabled"
                :tabindex="0"
                @mousedown.stop
              >
                <q-tooltip>
                  {{
                    group.isCompleted
                      ? $t("inquiryPositionsPage.offerPositionGroup.unfinalize")
                      : $t("inquiryPositionsPage.offerPositionGroup.finalize")
                  }}
                  <kbd class="kbd-dark q-ml-xs">f</kbd>
                </q-tooltip>
              </q-checkbox>
            </div>
            <q-icon
              name="sym_r_warning"
              size="1rem"
              color="warning"
              v-if="
                group.isOffered &&
                group.offerPositions.some(
                  (position) => position.amount === null
                )
              "
            >
              <q-tooltip>{{
                $t("inquiryPositionsPage.offerPositionGroup.missingAmount")
              }}</q-tooltip>
            </q-icon>
            <q-btn
              v-if="group.isManuallyCreated"
              :disable="disabled"
              dense
              flat
              size="sm"
              icon="sym_r_delete"
              color="neutral-7"
              @click="deleteGroup(group)"
            />
            <q-btn
              :tabindex="-1"
              dense
              flat
              size="sm"
              :icon="isExpanded ? 'sym_r_expand_less' : 'sym_r_expand_more'"
              @click="handleExpandButtonClick"
            >
              <q-tooltip>
                <template v-if="isExpanded">
                  {{ $t("inquiryPositionsPage.offerPositionGroup.collapse") }}
                  <kbd class="kbd-dark q-ml-xs">&larr;</kbd>
                </template>
                <template v-else>
                  {{ $t("inquiryPositionsPage.offerPositionGroup.expand") }}
                  <kbd class="kbd-dark q-ml-xs">&rarr;</kbd>
                </template>
              </q-tooltip>
            </q-btn>
          </div>
        </div>
      </q-item-section>
    </q-item>
    <div
      v-if="isExpanded"
      :class="[
        'positions-table-item-dropdown',
        'column',
        { selected: isSelected },
      ]"
      @keydown.stop.a
      @keydown.stop.f
      @keydown.stop.enter
    >
      <div class="row no-wrap justify-between items-baseline q-px-md q-py-xs">
        <q-space />
        <div class="row no-wrap items-baseline">
          <number-input
            ref="amountMultiplesInput"
            :value="group.amountMultiples"
            :precision="0"
            class="text-right q-mr-sm"
            style="width: 40px"
            @update:value="(value) => setAmountMultiples(value!)"
            :disabled="disabled"
            :nullable="false"
            @keydown.stop.left
            @keydown.stop.right
            @keydown.stop.escape="amountMultiplesInput?.blur()"
          />
          x
        </div>
      </div>
      <q-separator />
      <div class="column no-wrap">
        <template
          v-for="(position, idx) in group.offerPositions"
          :key="position.id"
        >
          <offer-position
            :ref="
              (el) => {
                offerPositionRefs[idx] = el as typeof OfferPosition | null;
              }
            "
            :position="position"
            :group="group"
            :disabled="disabled"
          />
          <q-separator />
        </template>
        <offer-position
          v-if="!disabled"
          :group="group"
          :disabled="disabled"
          @offer-position-added="selectNewlyAddedOfferPosition"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import NumberInput from "@/components/NumberInput.vue";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import { QItem, useQuasar } from "quasar";
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import MultiProductInput from "./MultiProductInput.vue";
import OfferPosition from "./OfferPosition.vue";
import ToggleOfferedButton from "./ToggleOfferedButton.vue";

const isExpanded = ref(false);
const q = useQuasar();
const { t } = useI18n();

const props = defineProps<{
  group: OfferPositionGroup;
  isSelected: boolean;
  disabled: boolean;
}>();

const emit = defineEmits<{
  activate: [];
  headerclick: [event: MouseEvent];
  setOffered: [isOffered: boolean];
  setCompleted: [isCompleted: boolean];
}>();

defineExpose({ expand, collapse, activate });

const { updateOfferPositionGroup, deleteOfferPositionGroup } =
  useCurrentOfferPositionGroupsStore();

const amountMultiplesInput = ref<typeof NumberInput | null | undefined>(null);

const isActive = ref(false);

const headerClass = computed(() => {
  return {
    "positions-table-item-header": true,
    offered: props.group.isOffered,
    completed: props.group.isOffered && props.group.isCompleted,
    selected: props.isSelected,
    active: isActive.value,
  };
});

const offerPositionRefs = ref<Record<number, typeof OfferPosition | null>>({});

const isCompletedCheckbox = computed({
  get: () => props.group.isCompleted && props.group.isOffered,
  set: (value: boolean) => {
    if (props.disabled || props.group.temporaryId) return;
    emit("setCompleted", value);
  },
});

function setAmountMultiples(value: number) {
  updateOfferPositionGroup(props.group.id, { amountMultiples: value });
}

const headerEl = ref<typeof QItem | null>(null);
const productInputEl = ref<typeof MultiProductInput | null>(null);
function select() {
  headerEl.value?.$el?.focus();
}

function activate() {
  productInputEl.value?.$el?.focus();
}

function deactivate() {
  productInputEl.value?.forceBlur();
}

watch(
  () => props.isSelected,
  (isSelected) => {
    if (!isSelected) {
      deactivate();
      referenceIdEl.value?.blur();
    }
  }
);

async function expand() {
  isExpanded.value = true;
  await nextTick();
  amountMultiplesInput.value?.focus();
  amountMultiplesInput.value?.select();
}

function collapse() {
  isExpanded.value = false;
  select();
}

function handleExpandButtonClick() {
  if (isExpanded.value) {
    collapse();
  } else {
    expand();
  }
}

function deleteGroup(group: OfferPositionGroup) {
  if (group.temporaryId) return;

  if (group.offerPositions.length === 0) {
    deleteOfferPositionGroup(group.id);
    return;
  }

  q.dialog({
    title: t("inquiryPositionsPage.offerPositionGroup.confirmDelete.title"),
    message: t("inquiryPositionsPage.offerPositionGroup.confirmDelete.message"),
    ok: {
      flat: true,
      dense: true,
      label: t("Delete"),
      color: "negative",
    },
    cancel: {
      flat: true,
      dense: true,
    },
  }).onOk(() => deleteOfferPositionGroup(group.id));
}

async function selectNewlyAddedOfferPosition() {
  await nextTick();
  const lastOfferPosition =
    offerPositionRefs.value[props.group.offerPositions.length - 1];
  lastOfferPosition?.select();
}

const referenceIdEl = ref<HTMLDivElement | null>(null);
function focusReferenceIdEl() {
  referenceIdEl.value?.focus();
}
function handleReferenceIdElBlur() {
  let newValue: string | null = referenceIdEl.value?.innerText || null;
  if (!newValue || !newValue.length) newValue = null; // backend doesn't accept empty strings
  updateOfferPositionGroup(props.group.id, { boqReferenceId: newValue });
}
</script>

<style lang="scss">
$reference-id-min-width: 60px;
$reference-id-max-width: 200px;

.positions-table-item {
  &-header {
    font-size: smaller;
    color: $neutral-6;
    background-color: $neutral-1;
    border-bottom: 1px solid $separator-color;

    > .q-focus-helper {
      // managing focus highlighting manually
      display: none;
    }

    &.offered {
      color: $neutral-10;
      background-color: $white;
    }

    &.completed {
      color: $neutral-10;
      background-color: $neutral-2;
    }

    &.selected {
      background-color: $primary-1;
    }

    &.selected .q-focus-helper {
      // no automatically set quasar item background on focus
      display: none !important;
    }

    &.active {
      .positions-table-item-reference-id {
        flex-shrink: 1;
      }

      .positions-table-item-short-text {
        flex-shrink: 1;
      }

      .positions-table-item-rest {
        width: 80%;
      }

      .positions-table-item-quick-input {
        width: unset;
        min-width: 20%;
        max-width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
        text-overflow: ellipsis;
      }
    }
  }

  &-toggle-offered-button {
    &:hover {
      color: $neutral-9;
    }
  }

  &-reference-id {
    min-width: $reference-id-min-width;
    max-width: $reference-id-max-width;

    &:focus-within {
      max-width: unset;
    }
  }

  &-rest {
    width: 45%;
  }

  &-quick-input {
    width: 100%;
    flex-shrink: 1;
  }

  &-amount {
    text-align: right;
    min-width: 50px;
    flex-shrink: 0;
    white-space: nowrap;
  }

  &-dropdown {
    font-size: smaller;
    border-bottom: 1px solid $separator-color;
    padding-left: 34px;

    &.selected {
      background-color: rgba($primary-1, 0.3);
    }

    .reference-id {
      font-weight: bold;
      font-size: regular;
      min-width: $reference-id-min-width;
      max-width: $reference-id-max-width;
      margin-right: 8px !important;
    }

    .text-short {
      font-weight: bold;
      font-size: regular;
    }
  }
}
</style>
