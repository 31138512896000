import * as api from "@/api/inquiry";
import { getInquiry } from "@/api/inquiry";
import { useRequestCancellation } from "@/composables/useRequestCancellation";
import { useRouteParams } from "@/composables/useRouteParams";
import { CLEAR_PREVIOUS_INQUIRY_AFTER_MS } from "@/config/constants";
import { useSearchFilterSortStore } from "@/stores/searchFilterSort";
import {
  CAN_EDIT_BASE_INQUIRY_STATUSES,
  CAN_EDIT_POSITIONS_BASE_INQUIRY_STATUSES,
  type Inquiry,
  type InquiryStatus,
  type ShortInquiry,
} from "@/types/inquiry";
import type { Tag } from "@/types/tag";
import type { User } from "@/types/user";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import type { Customer } from "@/types/customer";

export const useCurrentInquiryStore = defineStore("currentInquiry", () => {
  const { organizationId, inboxId, inquiryId } = useRouteParams();
  const router = useRouter();

  const inquiry = ref<Inquiry | null>(null);

  const is404 = ref(false);

  const { cancelsLoading, openLoadingRequests } = useRequestCancellation();

  const filterStore = useSearchFilterSortStore();

  async function loadData() {
    if (
      isNaN(organizationId.value) ||
      isNaN(inboxId.value) ||
      isNaN(inquiryId.value)
    ) {
      inquiry.value = null;
      return;
    }

    let isCancelled = false;
    const requestId = crypto.randomUUID();
    openLoadingRequests[requestId] = () => {
      isCancelled = true;
    };

    try {
      const apiQuery = filterStore.getApiQuery();
      let result;

      try {
        result = await getInquiry(
          organizationId.value,
          inboxId.value,
          inquiryId.value,
          apiQuery
        );
      } catch (error: any) {
        if (isCancelled) return;
        if (error.response?.status === 404) {
          is404.value = true;
          return;
        }
        throw error;
      }

      if (isCancelled) return;
      is404.value = false;

      inquiry.value = result;
    } finally {
      delete openLoadingRequests[requestId];
    }
  }
  loadData();
  watch(
    () => [organizationId.value, inboxId.value, inquiryId.value],
    async (newVal, oldVal) => {
      if (
        newVal[0] !== oldVal[0] ||
        newVal[1] !== oldVal[1] ||
        newVal[2] !== oldVal[2]
      ) {
        let hasLoaded = false;
        setTimeout(() => {
          if (!hasLoaded) {
            inquiry.value = null;
          }
        }, CLEAR_PREVIOUS_INQUIRY_AFTER_MS);
        await loadData();
        hasLoaded = true;
      }
    }
  );
  // Update if the route changes to the inquiry page
  watch(
    () => router.currentRoute.value.name,
    async (name, oldName) => {
      if (name === "inquiry" && oldName !== "inquiry") {
        await loadData();
      }
    }
  );

  async function baseUpdateStatus(status: InquiryStatus) {
    if (inquiry.value) inquiry.value.status = status;

    inquiry.value = await api.updateInquiry(inboxId.value, inquiryId.value, {
      status: status.name,
    });

    subscribeToNotifications();
  }

  async function baseUpdateAssignedUser(user: User | null) {
    if (inquiry.value) inquiry.value.assignedUser = user;

    inquiry.value = await api.updateInquiry(inboxId.value, inquiryId.value, {
      assignedUserId: user?.id || null,
    });

    subscribeToNotifications();
  }

  async function baseUpdateCustomer(customer: Customer | null) {
    if (inquiry.value) inquiry.value.customer = customer;

    inquiry.value = await api.updateInquiry(inboxId.value, inquiryId.value, {
      customerId: customer?.id || null,
    });

    subscribeToNotifications();
  }

  async function baseAddTag(tag: Tag) {
    if (inquiry.value && !inquiry.value.tags.includes(tag)) {
      inquiry.value.tags.push(tag);
    }

    await api.addTag(inboxId.value, inquiryId.value, tag.id);
    // TODO: error handling
  }

  async function baseRemoveTag(tag: Tag) {
    if (inquiry.value) {
      inquiry.value.tags = inquiry.value.tags.filter((t) => t.id !== tag.id);
    }

    await api.removeTag(inboxId.value, inquiryId.value, tag.id);
    // TODO: error handling
  }

  async function baseUpdateInquiry(update: Partial<Inquiry>) {
    if (inquiry.value) {
      Object.assign(inquiry.value, update);
    }

    inquiry.value = await api.updateInquiry(
      inboxId.value,
      inquiryId.value,
      update
    );

    subscribeToNotifications();
  }

  async function baseRetryAnalysis() {
    await retryInquiryAnalysis(
      inquiry.value,
      organizationId.value,
      inboxId.value
    );

    subscribeToNotifications();
  }

  const updateStatus = cancelsLoading(baseUpdateStatus);
  const updateAssignedUser = cancelsLoading(baseUpdateAssignedUser);
  const updateCustomer = cancelsLoading(baseUpdateCustomer);
  const addTag = cancelsLoading(baseAddTag);
  const removeTag = cancelsLoading(baseRemoveTag);
  const updateInquiry = cancelsLoading(baseUpdateInquiry);
  const retryAnalysis = cancelsLoading(baseRetryAnalysis);

  const canEditInquiryPositions = computed(
    () =>
      (inquiry.value &&
        CAN_EDIT_POSITIONS_BASE_INQUIRY_STATUSES.includes(
          inquiry.value.status.originalStatus
        )) ||
      false
  );

  const canEditInquiry = computed(
    () =>
      (inquiry.value &&
        CAN_EDIT_BASE_INQUIRY_STATUSES.includes(
          inquiry.value.status.originalStatus
        )) ||
      false
  );

  async function moveToAnotherInbox(targetInboxId: number) {
    if (!inboxId.value || !inquiryId.value) throw Error("No inquiry loaded");
    await subscribeToNotifications();

    const newInquiry = await api.moveInquiryToAnotherInbox(
      inboxId.value,
      inquiryId.value,
      targetInboxId
    );

    inquiry.value = newInquiry;

    router.replace({
      name: "inquiry",
      params: {
        organizationId: organizationId.value,
        inboxId: targetInboxId,
        inquiryId: inquiryId.value,
      },
    });
  }

  async function subscribeToNotifications() {
    if (!inquiry.value) return;
    const currentInquiryId = inquiryId.value;
    await api.subscribeToNotifications(inboxId.value, inquiryId.value);
    if (inquiry.value?.id != currentInquiryId) return;
    inquiry.value.isSubscribed = true;
  }

  async function unsubscribeFromNotifications() {
    if (!inquiry.value) return;
    const currentInquiryId = inquiryId.value;
    await api.unsubscribeFromNotifications(inboxId.value, inquiryId.value);
    if (inquiry.value?.id != currentInquiryId) return;
    inquiry.value.isSubscribed = false;
  }

  return {
    loadData,
    inquiry,
    is404,
    updateStatus,
    updateAssignedUser,
    updateCustomer,
    updateInquiry,
    retryAnalysis,
    addTag,
    removeTag,
    canEditInquiryPositions,
    canEditInquiry,
    moveToAnotherInbox,
    subscribeToNotifications,
    unsubscribeFromNotifications,
  };
});

export async function retryInquiryAnalysis(
  inquiry: Inquiry | ShortInquiry | null,
  organizationId: number,
  inboxId: number
) {
  if (!inquiry) return;
  const previousStatus = inquiry.status;
  inquiry.status = {
    id: "ANALYZING",
    name: "ANALYZING",
    originalStatus: "ANALYZING",
    isSelectable: false,
    color: "neutral-6",
    custom: false,
  };

  try {
    await api.retryAnalysis(organizationId, inboxId, inquiry.id);
  } catch (e) {
    if (previousStatus) inquiry.status = previousStatus;
  }
}
