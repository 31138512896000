<template>
  <q-table
    flat
    dense
    class="products-table"
    table-header-class="bg-neutral-3"
    row-key="id"
    :loading="isLoading"
    :columns="columns"
    :rows="products"
    :rows-per-page-options="[10, 20, 50, 100]"
    v-model:pagination="pagination"
    @request="(request) => $emit('request', request)"
  >
    <template #pagination="scope">
      {{ (scope.pagination.page - 1) * scope.pagination.rowsPerPage + 1 }}-
      {{
        Math.min(scope.pagination.page * scope.pagination.rowsPerPage, total)
      }}
      {{ $t("of") }} {{ total }}
      <q-btn
        icon="sym_r_chevron_left"
        color="neutral-8"
        round
        dense
        flat
        :disable="scope.isFirstPage || isLoading"
        @click="scope.prevPage"
      />
      <q-btn
        icon="sym_r_chevron_right"
        color="neutral-8"
        round
        dense
        flat
        :disable="scope.isLastPage || isLoading"
        @click="scope.nextPage"
      />
    </template>
    <template #body-cell-externalId="scope">
      <q-td :props="scope">
        <highlight-text :text="scope.value" :search-text="props.searchText" />
      </q-td>
    </template>
    <template #body-cell-name="scope">
      <q-td :props="scope">
        <highlight-text :text="scope.value" :search-text="props.searchText" />
      </q-td>
    </template>
  </q-table>
</template>

<script setup lang="ts">
import type { Product } from "@/types/product";
import type { QTableProps } from "quasar";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import HighlightText from "../HighlightText.vue";

const props = defineProps<{
  isLoading: boolean;
  products: Product[];
  pageSize: number;
  page: number;
  total: number;
  searchText: string;
}>();

defineEmits<{
  request: [request: { pagination: QTableProps["pagination"] }];
}>();

const pagination = computed({
  get: () => ({
    page: props.page,
    rowsPerPage: props.pageSize,
    rowsNumber: props.total,
  }),
  // Update is done via handleUpdate
  set: () => {},
});

const { t } = useI18n();

const columns: QTableProps["columns"] = [
  {
    name: "externalId",
    label: t("ID"),
    align: "left",
    field: "externalId",
    sortable: false,
  },
  {
    name: "name",
    label: t("Name"),
    align: "left",
    field: "name",
    sortable: false,
    style:
      "max-width: 400px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
  },
  {
    name: "description",
    label: t("Description"),
    align: "left",
    field: "description",
    sortable: false,
    style:
      "max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
  },
  {
    name: "unit",
    label: t("Unit"),
    align: "left",
    field: (p: Product) => t(`productUnit.${p.unit}`),
    sortable: false,
  },
  {
    name: "alternative_units",
    label: t("Alternative Units"),
    align: "left",
    field: (p: Product) =>
      p.alternativeUnits.map((unit) => t(`productUnit.${unit}`)).join(", "),
    sortable: false,
  },
  {
    name: "isActive",
    label: t("Active"),
    align: "left",
    field: "isActive",
    sortable: false,
    format: (val: boolean) => (val ? t("Yes") : t("No")),
  },
];
</script>

<style lang="scss">
.products-table {
  .q-table__middle {
    width: 100%;
  }

  th {
    color: $neutral-7;
  }

  thead tr th {
    position: sticky;
    z-index: 1;
  }

  thead tr:first-child th {
    top: 0;
  }

  /* this is when the loading indicator appears */
  &q-table--loading thead tr:last-child th {
    /* height of all previous header rows */
    top: 48px;
  }

  /* prevent scrolling behind sticky top row on focus */
  tbody {
    /* height of all previous header rows */
    scroll-margin-top: 48px;
  }
}
</style>
