<template>
  <q-btn
    icon="sym_r_download"
    color="neutral-7"
    flat
    dense
    :disable="isDisabled"
  >
    <q-menu>
      <q-list dense>
        <q-item clickable v-ripple v-close-popup @click="downloadExcel">
          <q-item-section>
            {{ $t("downloadInquiryButton.excel") }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
    <q-tooltip> {{ $t("downloadInquiryButton.tooltip") }}</q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import * as api from "@/api/inquiry";
import { useRouteParams } from "@/composables/useRouteParams";
import { computed } from "vue";

const { organizationId, inboxId, inquiryId } = useRouteParams();

const isDisabled = computed(() =>
  [organizationId.value, inboxId.value, inquiryId.value].some((id) => isNaN(id))
);

async function downloadExcel() {
  if (isDisabled.value) return;
  await api.downloadInquiryAsExcel(
    organizationId.value,
    inboxId.value,
    inquiryId.value
  );
}
</script>
